import React from "react";

import { Grid, makeStyles, Box, Link, Typography } from "@material-ui/core";

import { Link as RouterLink } from "react-router-dom";

import logoWhite from "../../assets/images/home/logoWhite.svg";

const useStyles = makeStyles((theme) => ({
  grid: {
    display: "flex",
    justifyContent: "center",
    minHeight: "275px",
    padding: theme.spacing(6, 0),
    textAlign: "center",
    backgroundColor: theme.palette.blackOne,
    [theme.breakpoints.down("md")]: {
      "& > *:nth-child(1)": {
        margin: "15px 0",
      },
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2, 0),
    },
  },
  gridItemImage: {
    display: "flex",
    justifyContent: "center",
  },
  logo: {
    width: "165px",
  },
  gridItemLinks: {
    maxHeight: "200px",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    alignItems: "center",
    "& > *": {
      padding: "10px",
    },
  },
  socialMedias: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      gap: "20px",
    },
  },
  text: {
    fontWeight: "500",
    color: theme.palette.common.white,
    letterSpacing: ".5px",
    transition: "all .25s ease-in-out 0s",
    "&:hover": {
      filter: "opacity(.8)",
    },
  },
  gridDisclaimer: {
    padding: theme.spacing(2),
  },
  underline: {
    textDecoration: "underline",
  },
  disclaimerText: {
    fontSize: "0.9rem",
    textAlign: "justify",
    color: theme.palette.grayOne,
  },
  socialMedia: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  socialMediaImage: {
    width: "22px",
    padding: "0 10px",
  },
}));

const Footer = ({ disclaimer = true }) => {
  const classes = useStyles();

  return (
    <Box>
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
        className={classes.grid}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={3}
          lg={2}
          className={classes.gridItemImage}
        >
          <RouterLink to="/">
            <img alt="Home" src={logoWhite} className={classes.logo} />
          </RouterLink>
        </Grid>

        <Grid
          item
          xs={12}
          sm={4}
          md={3}
          lg={2}
          className={classes.gridItemLinks}
        >
          <Link href="/about-us" underline="none" className={classes.text}>
            About us
          </Link>

          <Link
            href="/about-us#careers"
            underline="none"
            className={classes.text}
          >
            Careers
          </Link>

          <Link
            href="/about-us#contact"
            underline="none"
            className={classes.text}
          >
            Contact
          </Link>
        </Grid>

        <Grid
          item
          xs={12}
          sm={4}
          md={3}
          lg={2}
          className={classes.gridItemLinks}
        >
          <Link
            href={process.env.REACT_APP_PRIVACY_POLICY}
            underline="none"
            className={classes.text}
          >
            Privacy Policy
          </Link>

          <Link
            href="/terms-and-conditions"
            underline="none"
            className={classes.text}
          >
            T&C’s
          </Link>
          
          <Link
            href="https://medium.com/audaxly"
            target="_blank"
            underline="none"
            className={classes.text}
          >
            Blog
          </Link>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        sm={4}
        md={3}
        lg={2}
        className={classes.gridItemLinks}
      ></Grid>

      {disclaimer && (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          className={classes.gridDisclaimer}
        >
          <Typography className={classes.disclaimerText}>
            Audaxly provides a software, materials and assistance with the only
            purpose of helping you generate a compliant Privacy Policy for your
            website. In this sense, Audaxly shall not be considered a lawyer or
            a law firm and no Attorney - client relationship is established.{" "}
            <span className={classes.underline}>
              Audaxly does not engage in the practice of law and our software
              does not substitute professional legal consultancy
            </span>
            . We have done our best to provide software, that generates a
            compliant document, but it should be treated as an elaborated
            template and we advise you to seek legal advice, which is the only
            way to guarantee full compliance.
          </Typography>
        </Grid>
      )}
    </Box>
  );
};

export default Footer;
